.App {
    text-align: center;
    background-color: #20C3F0;
    margin-top: 66px;
}

.landing {
    width: 100%;
    margin: 0;
}

.mission {
    background-color: #20C3F0;
    color: #FFFFFF;
    margin: 0;
}

.mission p {
    margin: auto;
    max-width: 375px;
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
    padding: 30px 0 30px 0;
}

nav {
    height: 66px;
    width: 100%;
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    box-shadow: rgba(0, 0, 0, 0.498039) 0px 2px 4px 0px;
}

.leftNav {
    position: absolute;
    left: 0;
    height: 100%;
}

.logo {
    margin: 10px 0 0 20px;
    height: 40px;
    float: left;
}

.rightNav {
    position: absolute;
    right: 0;
    height: 100%;
}

.removeDecoration {
    text-decoration: none;
}

.loginButton {
    position: absolute;
    top: 16px;
    right: 16px;
}